import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { laneRef, checkpointRef, tenantRef, userRef, auth } from '@/plugins/firebaseInit'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authObject: null,
    status: {
      loggedIn: false,
      header: false
    },
    user: {},
    tenant: {},
    lane: {},
    checkpoint: {},
    checkpoint_id: null,
    tenant_id: null,
    areas: [],
    positions: [],
    dateFrom: new Date(),
    dateTo: new Date(),
    charts: [
      {
        chart_type: 'bar',
        category: 'lane',
        stacked: true,
        fill: true,
        label: 'datetime', // 'dow', 'hour'
        measure: 'passengers', // 'occupancy', 'avg_dwell_time'
        func: 'sum',
        filters: {
          lane: '',
          process: '',
          date_from: '2022-03-14',
          date_to: '2022-03-21',
          limit: 500
        }
      },
      {
        chart_type: 'line',
        category: 'date',
        stacked: false,
        fill: false,
        label: 'hour', // 'dow', 'hour'
        measure: 'passengers', // 'occupancy', 'avg_dwell_time'
        func: 'avg',
        filters: {
          lane: '',
          process: '',
          date_from: '2022-03-14',
          date_to: '2022-03-21',
          limit: 500
        }
      },
      // {
      //   chart_type: 'bar',
      //   category: 'lane',
      //   stacked: true,
      //   fill: true,
      //   label: 'hour', // 'dow', 'hour'
      //   measure: 'passengers', // 'occupancy', 'avg_dwell_time'
      //   func: 'avg',
      //   filters: {
      //     lane: '',
      //     process: '',
      //     date_from: '2022-03-14',
      //     date_to: '2022-03-21',
      //     limit: 500
      //   }
      // },
      {
        chart_type: 'line',
        category: 'lane',
        stacked: true,
        fill: true,
        label: 'hour', // 'dow', 'hour'
        measure: 'passengers', // 'occupancy', 'avg_dwell_time'
        func: 'avg',
        filters: {
          lane: '',
          process: '',
          date_from: '2022-03-14',
          date_to: '2022-03-21',
          limit: 500
        }
      },
      {
        chart_type: 'line',
        category: 'process',
        stacked: false,
        fill: false,
        label: 'hour', // 'dow', 'hour'
        measure: 'occupancy', // 'occupancy', 'avg_dwell_time'
        func: 'avg',
        filters: {
          lane: '',
          process: '',
          date_from: '2022-03-14',
          date_to: '2022-03-21',
          limit: 500
        }
      },
      {
        chart_type: 'line',
        category: 'process',
        stacked: false,
        fill: false,
        label: 'hour', // 'date', 'dow', 'hour'
        measure: 'avg_dwell_time', // 'occupancy', 'avg_dwell_time'
        func: 'avg',
        filters: {
          lane: '',
          process: '',
          date_from: '2022-03-14',
          date_to: '2022-03-21',
          limit: 500
        }
      },
      {
        chart_type: 'line',
        category: 'position',
        stacked: false,
        fill: false,
        label: 'hour', // 'dow', 'hour'
        measure: 'occupancy', // 'occupancy', 'avg_dwell_time'
        func: 'avg',
        filters: {
          lane: '',
          process: '',
          date_from: '2022-03-14',
          date_to: '2022-03-21',
          limit: 500
        }
      },
      {
        chart_type: 'line',
        category: 'position',
        stacked: false,
        fill: false,
        label: 'hour', // 'date', 'dow', 'hour'
        measure: 'avg_dwell_time', // 'occupancy', 'avg_dwell_time'
        func: 'avg',
        filters: {
          lane: '',
          process: '',
          date_from: '2022-03-14',
          date_to: '2022-03-21',
          limit: 500
        }
      }
    ]
  },
  mutations: {
    enableHeader (state, header) {
      state.status.header = header
    },
    loginSuccess (state, authObject) {
      state.status.loggedIn = true
      state.authObject = authObject
    },
    loginFailure (state) {
      state.status.loggedIn = false
      state.authObject = null
    },
    logout (state) {
      state.status.loggedIn = false
      state.checkpoint_id = null
      state.tenant = null
      state.checkpoint = null
      state.authObject = null
    },
    updateCp (state, cpNum) {
      state.checkpoint_id = cpNum
    },
    setTenant (state, tenant) {
      state.tenant_id = tenant
    },
    setPositions (state, data) {
      state.positions = data
    },
    setAreas (state, data) {
      state.areas = data
    },
    ...vuexfireMutations
  },
  actions: {
    bindTenant: firestoreAction(({
      bindFirestoreRef,
      state
    }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'tenant',
        tenantRef.doc(state.tenant_id)
      )
    }),
    unbindTenant: firestoreAction(({ unbindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return unbindFirestoreRef(
        'tenant',
        false
      )
    }),
    bindUser: firestoreAction(({
      bindFirestoreRef,
      state
    }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'user',
        userRef.doc(state.authObject.user.uid)
      )
    }),
    unbindUser: firestoreAction(({ unbindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return unbindFirestoreRef(
        'user'
      )
    }),
    bindLane: firestoreAction(({ bindFirestoreRef }, laneNum) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'lane',
        laneRef.doc(laneNum.toString())
      )
    }),
    unbindLane: firestoreAction(({ unbindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return unbindFirestoreRef(
        'lane'
      )
    }),
    bindCheckpoint: firestoreAction(({
      bindFirestoreRef,
      state
    }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'checkpoint',
        checkpointRef.doc(state.checkpoint_id.toString())
      )
    }),
    unbindCheckpoint: firestoreAction(({ unbindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return unbindFirestoreRef(
        'checkpoint',
        false
      )
    }),
    bindAreas: firestoreAction(({
      bindFirestoreRef,
      state
    }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'areas',
        tenantRef.doc(state.tenant_id).collection('area')
      )
    }),
    unbindAreas: firestoreAction(({ unbindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return unbindFirestoreRef(
        'areas',
        false
      )
    }),
    bindPositions: firestoreAction(({
      bindFirestoreRef,
      state
    }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'positions',
        checkpointRef.doc(state.checkpoint_id.toString()).collection('position')
      )
    }),
    unbindPositions: firestoreAction(({ unbindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return unbindFirestoreRef(
        'positions',
        false
      )
    }),
    async saveUserProfile ({
      commit,
      dispatch
    }, user) {
      // set user profile in state
      await user.getIdToken().then(function (idToken) {
        user.token = idToken
      })
      const authObject = {
        user: user
      }
      commit('loginSuccess', authObject)
      dispatch('updateTenant')
    },
    updateTenant ({
      commit,
      state,
      dispatch
    }) {
      dispatch('bindUser').then(() => {
        commit('setTenant', state.user.defaultTenant)
      }).then(() => {
        dispatch('bindTenant')
      })
    },
    logOut ({
      commit,
      dispatch
    }) {
      auth.signOut()
        .then(() => {
          commit('logout')
          dispatch('unbindUser')
          dispatch('unbindCheckpoint')
          dispatch('unbindTenant')
        })
    }
  },
  getters: {
    tenant: state => {
      return state.tenant
    },
    lane: state => {
      return state.lane
    },
    areas: state => {
      return state.areas
    },
    positions: state => {
      return state.positions
    },
    charts: state => {
      return state.charts
    },
    checkpoint: state => {
      return state.checkpoint
    },
    cpNum: state => {
      return state.checkpoint_id
    },
    loggedIn: state => {
      return state.status.loggedIn
    },
    showHeader: state => {
      return state.status.header
      // return ['/analysis-view', '/live-view'].includes(this.$router.)
    },
    dateFrom: state => {
      const dt = new Date()
      dt.setDate(state.dateTo.getDate() - 14)
      return dt.toISOString().slice(0, 10)
    },
    dateTo: state => {
      return state.dateTo.toISOString().slice(0, 10)
    }
  },
  modules: {}
})
