import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home'
import store from '@/store'
// import { analytics } from '@/plugins/firebaseInit'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   meta: {
  //     auth: false,
  //     header: false,
  //     title: 'GRASP - Home'
  //   }
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About'),
  //   meta: {
  //     auth: false,
  //     header: false
  //   }
  // },
  // {
  //   path: '/cp',
  //   name: 'Checkpoint',
  //   component: () => import(/* webpackChunkName: "cp" */ '../components/CheckPoint'),
  //   meta: {
  //     auth: true,
  //     header: false
  //   }
  // },
  {
    path: '/analysis-view',
    name: 'AnalysisView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bq" */ '../views/AnalysisView'),
    meta: {
      auth: true,
      header: true,
      title: 'GRASP - Analytics',
      metaTags: [
        {
          name: 'description',
          content: 'The analytical dashboards for historical GRASP-Innovations data.'
        },
        {
          property: 'og:description',
          content: 'The analytical dashboards for historical GRASP-Innovations data.'
        }
      ]
    }
  },
  {
    path: '/live-view',
    name: 'LiveView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rt" */ '../views/LiveView'),
    meta: {
      auth: true,
      header: true,
      title: 'GRASP - Live View',
      metaTags: [
        {
          name: 'description',
          content: 'The checkpoint real-time overview page.'
        },
        {
          property: 'og:description',
          content: 'The checkpoint real-time overview page.'
        }
      ]
    }
  },
  // {
  //   path: '/service',
  //   name: 'Service',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "service" */ '../views/Service'),
  //   meta: {
  //     auth: true,
  //     header: false
  //   }
  // },
  {
    path: '/user',
    name: 'User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/User'),
    meta: {
      auth: true,
      header: false,
      title: 'GRASP - Administration',
      metaTags: [
        {
          name: 'description',
          content: 'The administration page of GRASP-Innovations users.'
        },
        {
          property: 'og:description',
          content: 'The administration page of GRASP-Innovations users.'
        }
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
    meta: {
      auth: false,
      header: false,
      title: 'GRASP - Login',
      metaTags: [
        {
          name: 'description',
          content: 'The customer login page to GRASP-Innovations app.'
        },
        {
          property: 'og:description',
          content: 'The customer login page to GRASP-Innovations app.'
        }
      ]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (to.name === 'Login' || store.state.status.loggedIn) {
      // analytics.setUserProperties({email: store.state.user.email});
      // analytics.setUserId(store.state.user.email);
      // analytics.logEvent('screen_view', { screen_name: to.path })
      // analytics.logEvent('page_view', { page_name: to.path })
      next()
    } else {
      // alert('Must Login')
      console.log('no authObject in store.state...')
      next({ path: '/login' })
    }
  } else {
    // analytics.logEvent('screen_view', { screen_name: to.path });
    // analytics.logEvent('page_view', { page_name: to.path });
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.header)) {
    store.commit('enableHeader', true)
  } else {
    store.commit('enableHeader', false)
  }
  next()
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (nearestWithMeta) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag))
  }
  next()
})

export default router
