import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'
// require('dotenv').config()
// console.log(process.env)

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
if (process.env.NODE_ENV === 'testing') {
  db.useEmulator('localhost', 8082)
}

const auth = firebase.auth()
const analytics = firebase.analytics()
const laneRef = db.collection('lane')
const checkpointRef = db.collection('checkpoint')
const userRef = db.collection('user')
const tenantRef = db.collection('tenant')
const readingRef = db.collection('reading')

// export utils/refs
export {
  firebase,
  analytics,
  auth,
  laneRef,
  checkpointRef,
  userRef,
  tenantRef,
  readingRef
}
