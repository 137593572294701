<template>
  <div id="app">
    <div id="nav" v-if="loggedIn">
      <router-link to="/live-view">{{ $t('nav.liveview') }}</router-link>
      |
      <span v-if="loggedIn" @click="logOut"><a href="#">{{ $t('nav.logout') }}</a></span>
      <div class="locale-changer">
        <select v-model="$i18n.locale">
          <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
            {{ lang }}
          </option>
        </select>
      </div>
    </div>
    <Header v-if="showHeader"/>
    <router-view/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { mapGetters } from 'vuex'
// import { auth } from '@/plugins/firebaseInit'

export default {
  name: 'Homepage',
  components: { Header },
  data () {
    return {
      langs: ['nl', 'it', 'en']
    }
  },
  computed: {
    ...mapGetters(['loggedIn', 'showHeader'])
  },
  methods: {
    logOut () {
      this.$store.dispatch('logOut')
      this.$router.push('/login')
    }
  },
  created () {
    if (!this.loggedIn) {
      this.$router.push({ path: '/login' })
      // this.bindItems()
    } else {
      this.$router.push({ path: '/live-view' })
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-display: auto; /*block = instructs the browser to briefly hide the text until the font has fully downloaded*/
  unicode-range: U+000-5FF;
  src: local('Roboto'),
  url('assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
  url('assets/fonts/Roboto/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-style: normal;
  font-display: auto; /*block = instructs the browser to briefly hide the text until the font has fully downloaded*/
  src: local('Roboto'),
  url('assets/fonts/Roboto/Roboto-Black.woff2') format('woff2'),
  url('assets/fonts/Roboto/Roboto-Black.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 500;
  font-style: normal;
  font-display: auto; /*block = instructs the browser to briefly hide the text until the font has fully downloaded*/
  src: local('cormorant_garamond'),
  url('assets/fonts/Cormorant Garamond/cormorantgaramond-medium-webfont.woff2') format('woff2'),
  url('assets/fonts/Cormorant Garamond/cormorantgaramond-medium-webfont.woff') format('woff');
}

#app {
  font-family: 'Roboto', 'Cormorant Garamond', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2D1D60;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

#nav {
  padding: 10px;
}

#nav a {
  font-weight: bold;
  color: #2D1D60;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.locale-changer {
  display: inline-block;
  margin-left: 30px;
  align-self: center;
}

:root {
  --vs-colors--lightest: rgba(60, 60, 60, 0.26);
  --vs-colors--light: rgba(60, 60, 60, 0.5);
  --vs-colors--dark: #333;
  --vs-colors--darkest: rgba(0, 0, 0, 0.15);

  /* Search Input */
  --vs-search-input-color: inherit;
  --vs-search-input-placeholder-color: inherit;

  /* Font */
  --vs-font-size: 1rem;
  --vs-line-height: 1.4;

  /* Disabled State */
  --vs-state-disabled-bg: rgb(248, 248, 248);
  --vs-state-disabled-color: var(--vs-colors--light);
  --vs-state-disabled-controls-color: var(--vs-colors--light);
  --vs-state-disabled-cursor: not-allowed;

  /* Borders */
  --vs-border-color: #2D1D6080;;
  --vs-border-width: 2px;
  --vs-border-style: solid;
  --vs-border-radius: 20px;

  /* Actions: house the component controls */
  --vs-actions-padding: 4px 6px 0 3px;

  /* Component Controls: Clear, Open Indicator */
  --vs-controls-color: var(--vs-colors--light);
  --vs-controls-size: 1;
  --vs-controls--deselect-text-shadow: 0 1px 0 #fff;

  /* Selected */
  --vs-selected-bg: #f0f0f0;
  --vs-selected-color: var(--vs-colors--dark);
  --vs-selected-border-color: var(--vs-border-color);
  --vs-selected-border-style: var(--vs-border-style);
  --vs-selected-border-width: var(--vs-border-width);

  /* Dropdown */
  --vs-dropdown-bg: #fff;
  --vs-dropdown-color: inherit;
  --vs-dropdown-z-index: 1000;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 350px;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);

  /* Options */
  --vs-dropdown-option-bg: #000;
  --vs-dropdown-option-color: var(--vs-dropdown-color);
  --vs-dropdown-option-padding: 3px 20px;

  /* Active State */
  --vs-dropdown-option--active-bg: #5897fb;
  --vs-dropdown-option--active-color: #fff;

  /* Deselect State */
  --vs-dropdown-option--deselect-bg: #fb5858;
  --vs-dropdown-option--deselect-color: #fff;

  /* Transitions */
  --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  --vs-transition-duration: 150ms;
}

select, input {
  margin: 0 5px;
  padding: 1px 5px;
  border-color: #2D1D6080;
  border-radius: 20px;
}
</style>
