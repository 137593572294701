import Vue from 'vue'
import App from './App.vue'
import { auth, analytics } from '@/plugins/firebaseInit'
import store from './store'
import router from './router'
import i18n from '@/plugins/i18n'

Vue.config.productionTip = false

// let app
// auth.onAuthStateChanged(user => {
//   if (!app) {
//     app = new Vue({
//       router,
//       store,
//       i18n,
//       render: h => h(App)
//     }).$mount('#app')
//   }
//   if (user) {
//     store.dispatch('saveUserProfile', user)
//   }
// })

auth.onAuthStateChanged(user => {
  if (user) {
    store.dispatch('saveUserProfile', user)
    analytics.setUserProperties({ email: user.email })
    // store.dispatch('updateTenant')
    // .then(() => {
    //   router.replace('/live-view')
    // })
    // } else {
    //   console.log('no user in main.js...')
    //   router.push('/login')
  }
})
new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
